<template>
    <div>
      <h2 class="body-1 lightBlue--text mb-5">{{$t('flightSearch.departure')}}</h2>
      <v-row>
        <v-col class="py-0" cols="12" :sm="$route.path === '/' ? 12 : 6" :md="$route.path === '/' ? 6 : 12">
          <autocomplete tripType="from" @error="displayError" />
        </v-col>
        <v-col class="py-0" cols="12" :sm="$route.path === '/' ? 12 : 6" :md="$route.path === '/' ? 6 : 12">
          <!-- destination airport -->
          <v-select
          prepend-inner-icon="mdi-airplane-landing"
          :items="destinationAirports"
          v-model="destination"
          item-text="Name"
          item-value="Code"
          color="headers"
          return-object
          outlined
          rounded
          flat
          item-color="blue"
          :label="$t('flightSearch.to')"
          >
            <template v-slot:item="{ item }">
              <span class="blueDark--text">
                {{item.Name + ', ' + item.Code}}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span class="blueDark--text">
                {{item.Name+ ', ' + item.Code}}
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <h2 class="body-1 lightBlue--text my-5">{{$t('flightSearch.return')}}</h2>
      <v-row>
        <v-col class="py-0" cols="12" :sm="$route.path === '/' ? 12 : 6" :md="$route.path === '/' ? 6 : 12">
        <!-- return airport -->
          <v-select
          prepend-inner-icon="mdi-airplane-takeoff"
          :items="returnAirports"
          v-model="returnSaudiAirport"
          item-text="Name"
          item-value="Code"
          color="headers"
          return-object
          outlined
          rounded
          flat
          item-color="blue"
          :label="$t('flightSearch.from')"
          >
            <template v-slot:item="{ item }">
              <span class="blueDark--text">
                {{item.Name + ', ' + item.Code}}
              </span>
            </template>
            <template v-slot:selection="{ item }">
              <span class="blueDark--text">
                {{item.Name+ ', ' + item.Code}}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col class="py-0" cols="12" :sm="$route.path === '/' ? 12 : 6" :md="$route.path === '/' ? 6 : 12">
          <autocomplete tripType="to" @error="displayError" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" :md="$route.path === '/' ? 6 : 12" class="py-0">
          <h2 class="lightBlue--text body-1 col-12">{{$t('flightSearch.tripStartDate')}}</h2>
          <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tempStartDateText"
                readonly
                v-bind="attrs"
                rounded
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
            v-model="tempDates[0]"
            :min="new Date(packageDetails.season_validation[0].end_season_ongoing_from).toISOString().substring(0, 10)"
            :max="new Date(packageDetails.season_validation[0].end_season_ongoing).toISOString().substring(0, 10)"
            show-current="false"
            color="headersText darken-3"
            no-title
            show-adjacent-months
            @input="startDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" :md="$route.path === '/' ? 6 : 12" class="py-0">
          <h2 class="lightBlue--text body-1 col-12">{{$t('flightSearch.tripReturnDate')}}</h2>
          <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tempEndDateText"
                readonly
                v-bind="attrs"
                v-on="on"
                rounded
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
            v-model="tempDates[1]"
            :min="packageReturnMinDate"
            :max="packageReturnMaxDate"
            show-current="false"
            color="headersText darken-3"
            no-title
            show-adjacent-months
            :readonly="!tempDates[0]"
            @input="endDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <!-- flight class -->
      <v-row>
        <v-col cols="12" :md="$route.path === '/' ? 6 : 12">
          <h2 class="lightBlue--text body-1 my-3">{{$t('flightSearch.tripClass')}}</h2>
          <v-select
          :items="classes"
          v-model="classType"
          color="headers"
          return-object
          rounded
          outlined
          flat
          hide-details
          item-color="headers"
          @change="setClass"
          >
            <template v-slot:selection="{ item }">
              <span class="blueDark--text">
                {{item.text}}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" :md="$route.path === '/' ? 6 : 12">
          <h2 class="lightBlue--text body-1 col-12">{{$t('flightSearch.occupancy')}}</h2>
          <v-menu
          v-model="occupancyMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="occupancyLabel"
                readonly
                v-bind="attrs"
                v-on="on"
                rounded
                outlined
              ></v-text-field>
            </template>
            <v-card class="pa-5">
              <v-row>
                <v-col cols="12" :sm="$route.path === '/' ? 6 : 3" :md="$route.path === '/' ? 3 : 6">
                  <div class="text-center">
                    <span class="text-h6 headers--text font-weight-medium mb-2">{{$t('flightSearch.occupancyTerms.adults')}}</span>
                    <div class="d-flex align-center justify-center mb-2">
                        <v-btn :disabled="adults >= 4" small elevation="0" tile
                        color="headers white--text"
                        class="pa-5 font-weight-bold text-h6"
                        @click="changePassengersNumbers('adults', 'inc')">+</v-btn>

                        <div class="headers white--text lighten-2 py-1 px-2 text-h6">{{adults}}</div>

                        <v-btn :disabled="adults <= 1" small elevation="0" tile
                        color="headers white--text"
                        class="pa-5 font-weight-bold text-h6"
                        @click="changePassengersNumbers('adults', 'dec')">-</v-btn>
                    </div>
                  </div>
                  <small class="d-block text-center">{{$t('flightSearch.occupancyTerms.adultAge')}}</small>
                </v-col>
                <v-col cols="12" :sm="$route.path === '/' ? 6 : 3" :md="$route.path === '/' ? 3 : 6">
                  <div class="text-center">
                    <span class="text-h6 headers--text font-weight-medium mb-2">{{$t('flightSearch.occupancyTerms.children')}}</span>
                    <div class="d-flex align-center justify-center mb-2">
                        <v-btn :disabled="oldChildren + adults >= 4" small elevation="0" tile
                        color="headers white--text"
                        class="pa-5 font-weight-bold text-h6"
                        @click="changePassengersNumbers('oldChildren', 'inc')">+</v-btn>

                        <div class="headers white--text lighten-2 py-1 px-2 text-h6">{{oldChildren}}</div>

                        <v-btn :disabled="oldChildren < 1" small elevation="0" tile
                        color="headers white--text"
                        class="pa-5 font-weight-bold text-h6"
                        @click="changePassengersNumbers('oldChildren', 'dec')">-</v-btn>
                    </div>
                  </div>
                  <small class="d-block text-center">{{$t('flightSearch.occupancyTerms.childAgeOld')}}</small>
                </v-col>
                <v-col cols="12" :sm="$route.path === '/' ? 6 : 3" :md="$route.path === '/' ? 3 : 6">
                  <div class="text-center">
                    <span class="text-h6 headers--text font-weight-medium mb-2">{{$t('flightSearch.occupancyTerms.children')}} </span>
                    <div class="d-flex align-center justify-center mb-2">
                      <v-btn :disabled="children >= 4" small elevation="0" tile
                      color="headers white--text"
                      class="pa-5 font-weight-bold text-h6"
                      @click="changePassengersNumbers('children', 'inc')">+</v-btn>

                      <div class="headers white--text lighten-2 py-1 px-2 text-h6">{{children}}</div>

                      <v-btn :disabled="children < 1" small elevation="0" tile
                      color="headers white--text"
                      class="pa-5 font-weight-bold text-h6"
                      @click="changePassengersNumbers('children', 'dec')">-</v-btn>
                    </div>
                  </div>
                  <small class="d-block text-center">{{$t('flightSearch.occupancyTerms.childAge')}}</small>
                </v-col>
                <v-col cols="12" :sm="$route.path === '/' ? 6 : 3" :md="$route.path === '/' ? 3 : 6">
                  <div class="text-center">
                    <span class="text-h6 headers--text font-weight-medium mb-2">{{$t('flightSearch.occupancyTerms.infants')}}</span>
                    <div class="d-flex align-center justify-center mb-2">
                      <v-btn :disabled="infants >= adults" small elevation="0" tile
                      color="headers white--text"
                      class="pa-5 font-weight-bold text-h6"
                      @click="changePassengersNumbers('infants', 'inc')">+</v-btn>

                      <div class="headers white--text lighten-2 py-1 px-2 text-h6">{{infants}}</div>

                      <v-btn :disabled="infants < 1" small elevation="0" tile
                      color="headers white--text"
                      class="pa-5 font-weight-bold text-h6"
                      @click="changePassengersNumbers('infants', 'dec')">-</v-btn>
                      </div>
                  </div>
                </v-col>
              </v-row>
              <div v-if="oldChildren + adults >= 4">
                <v-divider class="my-2"></v-divider>
                <p class="caption error--text mb-0 mt-2" v-html="$t('flightSearch.occupancyTerms.passengersLimitNote')"></p>
              </div>
              <v-divider class="my-2"></v-divider>
              <p class="caption error--text mb-0 mt-2">{{$t('flightSearch.occupancyTerms.infantsNote')}}</p>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import autocomplete from '@/components/flights/autocomplete'
import { mapState } from 'vuex'

export default {
  components: { autocomplete },
  data () {
    return {
      returnSaudiAirport: null,
      destination: null,
      tempDates: [],
      startDateMenu: false,
      endDateMenu: false,
      occupancyMenu: false
    }
  },
  watch: {
    returnSaudiAirport (value) {
      this.$store.dispatch('setReturnAirport', value)
    },
    destination (value) {
      this.$store.dispatch('setDestinationAirport', value)
    },
    tempDates (val) {
      if (new Date(val[0]).getTime() < new Date(val[1]).getTime()) {
        this.$store.dispatch('updateStartDate', val[0])
        this.$store.dispatch('updateReturnDate', val[1])
      } else {
        this.$store.dispatch('updateStartDate', val[1])
        this.$store.dispatch('updateReturnDate', val[0])
      }
      if (new Date(val[1]).getTime() < new Date(this.packageReturnMinDate).getTime()) this.tempDates[1] = this.packageReturnMinDate
    }
  },
  computed: {
    ...mapState(['packageDetails', 'startDate', 'returnDate', 'destinationAirport', 'returnAirport', 'adults', 'children', 'oldChildren', 'infants']),
    destinationAirports () {
      if (this.returnAirport.Code === 'MED') {
        return [{
          Code: 'JED',
          Name: 'King Abdulaziz Int',
          City: 'Jeddah',
          countryCode: 'SA',
          Country: 'Saudi Arabia',
          Type: 'A'
        }]
      } else {
        return [
          {
            Code: 'JED',
            Name: 'King Abdulaziz Int',
            City: 'Jeddah',
            countryCode: 'SA',
            Country: 'Saudi Arabia',
            Type: 'A'
          },
          {
            Code: 'MED',
            Name: 'Madinah',
            City: 'Madinah',
            countryCode: 'SA',
            Country: 'Saudi Arabia',
            Type: 'A'
          }
        ]
      }
    },
    returnAirports () {
      if (this.destinationAirport.Code === 'MED') {
        return [{
          Code: 'JED',
          Name: 'King Abdulaziz Int',
          City: 'Jeddah',
          countryCode: 'SA',
          Country: 'Saudi Arabia',
          Type: 'A'
        }]
      } else {
        return [
          {
            Code: 'JED',
            Name: 'King Abdulaziz Int',
            City: 'Jeddah',
            countryCode: 'SA',
            Country: 'Saudi Arabia',
            Type: 'A'
          },
          {
            Code: 'MED',
            Name: 'Madinah',
            City: 'Madinah',
            countryCode: 'SA',
            Country: 'Saudi Arabia',
            Type: 'A'
          }
        ]
      }
    },
    tempStartDateText () {
      if (this.tempDates[0]) {
        // return new Date(this.tempDates[0]).toDateString()
        return this.$d(new Date(this.tempDates[0].replace(/-/g, '/')), 'short')
      } else return ''
    },
    tempEndDateText () {
      if (this.tempDates[1]) {
        // return new Date(this.tempDates[1]).toDateString()
        return this.$d(new Date(this.tempDates[1].replace(/-/g, '/')), 'short')
      } else return ''
    },
    packageReturnMinDate () {
      const minDate = this.tempDates[0] ? new Date(this.tempDates[0].replace(/-/g, '/')) : new Date()
      minDate.setDate(minDate.getDate() + 3)
      return minDate.toISOString().substr(0, 10)
    },
    packageReturnMaxDate () {
      let maxDate = this.tempDates[0] ? new Date(this.tempDates[0].replace(/-/g, '/')) : new Date()
      maxDate.setMonth(maxDate.getMonth() + 1)
      if (new Date(maxDate).getTime() > new Date(this.packageDetails.season_validation[0].end_season_ongoing).getTime()) maxDate = new Date(this.packageDetails.season_validation[0].end_season_ongoing)
      return maxDate.toISOString().substr(0, 10)
    },
    classType () {
      return { text: this.translateClass('economy'), value: 'Y' }
    },
    classes () {
      return [
        { text: this.translateClass('economy'), value: 'Y' },
        { text: this.translateClass('premium'), value: 'S' },
        { text: this.translateClass('business'), value: 'C' },
        { text: this.translateClass('first'), value: 'F' }
      ]
    },
    occupancyLabel () {
      return this.$tc('flightSearch.occupancyTerms.adult', this.adults) + ' - ' +
      this.$tc('flightSearch.occupancyTerms.child', this.children + this.oldChildren) + ' - ' +
      this.$tc('flightSearch.occupancyTerms.infant', this.infants)
    }
  },
  methods: {
    setClass () {
      this.$store.dispatch('setPassengersClass', this.classType)
    },
    translateClass (classLabel) {
      return this.$t(`flightSearch.classes.${classLabel}`)
    },
    changePassengersNumbers (passengerType, method) {
      this.$store.dispatch('changePassengersNumbers', { type: passengerType, action: method })
      if (this.infants > this.adults) this.$store.dispatch('changePassengersNumbers', { type: 'infants', action: 'dec' })
      if (this.oldChildren + this.adults > 4) this.$store.dispatch('changePassengersNumbers', { type: 'oldChildren', action: 'dec' })
    },
    displayError (msg) {
      this.$emit('error', msg)
    }
  },
  created () {
    if (this.$store.state.originAirport) this.search = this.$store.state.originAirport.Code
    if (this.$store.state.destinationAirport) this.destination = this.$store.state.destinationAirport
    else this.destination = this.$store.state.packageDetails.packageStartCity[0]
    if (this.$store.state.returnAirport) this.returnSaudiAirport = this.$store.state.returnAirport
    else if (this.$store.state.destinationAirport.Code === 'MED') {
      this.returnSaudiAirport = {
        Code: 'JED',
        Name: 'King Abdulaziz Int',
        City: 'Jeddah',
        countryCode: 'SA',
        Country: 'Saudi Arabia',
        Type: 'A'
      }
    } else this.returnSaudiAirport = this.$store.state.packageDetails.packageStartCity[0]
    if (this.startDate) this.tempDates[0] = this.startDate
    else {
      this.tempDates = [
        new Date(this.packageDetails.season_validation[0].end_season_ongoing_from).toISOString().substring(0, 10),
        new Date(this.packageDetails.season_validation[0].end_season_return_from).toISOString().substring(0, 10)
      ]
    }
    if (this.returnDate) {
      this.tempDates[1] = this.returnDate
    }
  }
}
</script>

<style>
.v-date-picker-table .v-btn {
  font-size: 16px !important;
  font-weight: 700;
  margin-bottom: 0.25px;
}
.v-date-picker-header__value {
  font-size: 18px;
}
</style>
