import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currency: {
      code: 'SAR',
      countryName: 'SAUDI ARABIA',
      rate: 1,
      name: 'RIYAL'
    },
    currencies: null,
    packages: [],
    packageDetails: null,
    adults: 2,
    children: 0,
    oldChildren: 0,
    infants: 0,
    startDate: null,
    returnDate: null,
    originAirport: null,
    destinationAirport: {
      Code: 'JED',
      Name: 'King Abdulaziz Int',
      City: 'Jeddah',
      countryCode: 'SA',
      Country: 'Saudi Arabia',
      Type: 'A'
    },
    returnAirport: {
      Code: 'JED',
      Name: 'King Abdulaziz Int',
      City: 'Jeddah',
      countryCode: 'SA',
      Country: 'Saudi Arabia',
      Type: 'A'
    },
    homeAirport: null,
    classType: { text: 'Economy', value: 'Y' },
    flight: null,
    writtenAddress: '',
    zipCode: '',
    writtenCity: 'US',
    writtenState: '',
    writtenCountry: '',
    packagePrice: 0,
    flightResults: null,
    makkahHotel: null,
    madinaHotel: null,
    cities: [],
    stayFromDate: null,
    stayToDate: null
  },
  getters: {
    packageDetails (state) {
      const details = state.packageDetails
      details.cities.forEach(city => {
        if (city.code === 'MED') city.cityHotel = state.madinaHotel
        else city.cityHotel = state.makkahHotel
      })
      return details
    }
  },
  mutations: {
    setCurrency (state, payload) {
      state.currency = payload
    },
    setCurrencies (state, payload) {
      state.currencies = payload
    },
    setPackages (state, payload) {
      state.packages = payload
    },
    setPackage (state, payload) {
      state.packageDetails = payload
    },
    setCities (state, payload) {
      state.packageDetails.cities = payload
    },
    setPackageNights (state, payload) {
      state.packageDetails.nights = payload
    },

    // hotel mutations
    setMakkahHotel (state, payload) {
      state.makkahHotel = payload
    },
    setMadinaHotel (state, payload) {
      state.madinaHotel = payload
    },
    increaseHotelNights (state, payload) {
      state.packageDetails.cities.map(item => item === payload ? item.cityNightNumber++ : null)
    },
    decreaseHotelNights (state, payload) {
      state.packageDetails.cities.map(item => item === payload ? item.cityNightNumber-- : null)
    },
    setStayDates (state, payload) {
      state.stayFromDate = payload.from
      state.stayToDate = payload.to
    },

    // date mutation
    updateStartDate (state, payload) {
      state.startDate = payload
    },
    updateReturnDate (state, payload) {
      state.returnDate = payload
    },

    // passenger mutations
    increaseAdultsNumbers (state) {
      state.adults++
    },
    decreaseAdultsNumbers (state) {
      state.adults--
    },
    increaseChildrenNumbers (state) {
      state.children++
    },
    decreaseChildrenNumbers (state) {
      state.children--
    },
    increaseOldChildrenNumbers (state) {
      state.oldChildren++
    },
    decreaseOldChildrenNumbers (state) {
      state.oldChildren--
    },
    increaseInfantsNumbers (state) {
      state.infants++
    },
    decreaseInfantsNumbers (state) {
      state.infants--
    },

    // flight airport mutation
    setOriginAirport (state, payload) {
      state.originAirport = payload
    },

    setDestinationAirport (state, payload) {
      state.destinationAirport = payload
    },

    setReturnAirport (state, payload) {
      state.returnAirport = payload
    },

    setHomeAirport (state, payload) {
      state.homeAirport = payload
    },

    // flight class mutations
    setPassengersClass (state, payload) {
      state.classType = payload
    },

    // flight mutations
    setFlight (state, payload) {
      state.flight = payload
    },
    removeFlight (state) {
      state.flight = null
    },
    setFlightResults (state, payload) {
      state.flightResults = payload
    },
    removeFlightResults (state) {
      state.flightResults = null
    },

    // address info mutations
    setWrittenAddress (state, payload) {
      state.writtenAddress = payload
    },
    setZipCode (state, payload) {
      state.zipCode = payload
    },
    setWrittenCity (state, payload) {
      state.writtenCity = payload
    },
    setWrittenState (state, payload) {
      state.writtenState = payload
    },
    setWrittenCountry (state, payload) {
      state.writtenCountry = payload
    },

    // package price
    setPackagePrice (state, payload) {
      state.packagePrice = payload
    }
  },
  actions: {
    setCurrency ({ commit }, payload) {
      commit('setCurrency', payload)
    },
    setCurrencies ({ commit }, payload) {
      commit('setCurrencies', payload)
    },
    setPackages ({ commit }, payload) {
      commit('setPackages', payload)
    },
    setPackage ({ commit }, payload) {
      commit('setPackage', payload)
    },
    setCities ({ commit }, payload) {
      commit('setCities', payload)
    },
    setPackageNights ({ commit }, payload) {
      commit('setPackageNights', payload)
    },

    // hotel actions
    changeHotelsNights  (context, payload) {
      const city = context.state.packageDetails.cities.find(city => city.cityID === payload.id)
      payload.action === 'inc' ? context.commit('increaseHotelNights', city) : context.commit('decreaseHotelNights', city)
    },
    setMakkahHotel ({ commit }, payload) {
      commit('setMakkahHotel', payload)
    },
    setMadinaHotel ({ commit }, payload) {
      commit('setMadinaHotel', payload)
    },
    setStayDates ({ commit }, payload) {
      commit('setStayDates', payload)
    },

    // passengers actions
    changePassengersNumbers ({ commit }, payload) {
      if (payload.type === 'adults') {
        payload.action === 'inc' ? commit('increaseAdultsNumbers') : commit('decreaseAdultsNumbers')
      } else if (payload.type === 'children') {
        payload.action === 'inc' ? commit('increaseChildrenNumbers') : commit('decreaseChildrenNumbers')
      } else if (payload.type === 'oldChildren') {
        payload.action === 'inc' ? commit('increaseOldChildrenNumbers') : commit('decreaseOldChildrenNumbers')
      } else {
        payload.action === 'inc' ? commit('increaseInfantsNumbers') : commit('decreaseInfantsNumbers')
      }
    },

    // date actions
    updateStartDate ({ commit }, payload) {
      commit('updateStartDate', payload)
    },
    updateReturnDate ({ commit }, payload) {
      commit('updateReturnDate', payload)
    },

    // flight airport
    setOriginAirport ({ commit }, payload) {
      commit('setOriginAirport', payload)
    },

    setDestinationAirport ({ commit }, payload) {
      commit('setDestinationAirport', payload)
    },

    setReturnAirport ({ commit }, payload) {
      commit('setReturnAirport', payload)
    },

    setHomeAirport ({ commit }, payload) {
      commit('setHomeAirport', payload)
    },

    // flight actions
    setPassengersClass ({ commit }, payload) {
      commit('setPassengersClass', payload)
    },
    setFlight ({ commit }, payload) {
      commit('setFlight', payload)
    },
    removeFlight ({ commit }) {
      commit('removeFlight')
    },
    setFlightResults ({ commit }, payload) {
      commit('setFlightResults', payload)
    },
    removeFlightResults ({ commit }) {
      commit('removeFlightResults')
    },

    // address info
    setWrittenAddress ({ commit }, payload) {
      commit('setWrittenAddress', payload)
    },
    setZipCode ({ commit }, payload) {
      commit('setZipCode', payload)
    },
    setWrittenCity (context, payload) {
      context.commit('setWrittenCity', payload)
    },
    setWrittenState (context, payload) {
      context.commit('setWrittenState', payload)
    },
    setWrittenCountry (context, payload) {
      context.commit('setWrittenCountry', payload)
    },

    // package price
    setPackagePrice ({ commit }, payload) {
      commit('setPackagePrice', payload)
    }
  },
  modules: {
  }
})
