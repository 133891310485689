import Vue from 'vue'
import { baseAPIURL } from '../APILinks'

const timeout = 20000
export default {
  // get all packages
  getPackages () {
    return Vue.axios.get(baseAPIURL + 'package',
      {
        timeout: timeout,
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  // get one package details
  getPackage (id) {
    return Vue.axios.get(baseAPIURL + 'package/' + id,
      {
        timeout: timeout,
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  // get package hotels
  getHotels (body) {
    return Vue.axios.post(baseAPIURL + 'package/getavailability',
      body,
      {
        timeout: 20000,
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  // check Package Price
  checkPackagePrice (body) {
    return Vue.axios.post(baseAPIURL + 'package/getPrice',
      body,
      {
        timeout: 20000,
        headers: {
          'Content-Type': 'application/json'
        }
      })
  },

  // save package
  savePackage (body) {
    return Vue.axios.post(baseAPIURL + 'package/bookPackage',
      body,
      {
        timeout: 20000,
        headers: {
          'Content-Type': 'application/json'
        }
      })
  }
}
