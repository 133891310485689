<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown">
    <v-snackbar
    :timeout="3000"
    v-model="alertExist"
    top
    :color="alertType"
    elevation="24"
    >
      {{alertText}}
    </v-snackbar>

    <div v-if="!loading">
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <template v-if="packageDetails">
        <h1 class="text-h2 font-weight-bold text-center white--text my-15">{{$t('basic.mainTitle')}}</h1>
        <p class="headline white--text text-center mb-15">{{$t('basic.slogan')}}</p>

        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="primary--text text-h5">{{$t('basic.packageContent')}}</v-expansion-panel-header>
            <v-expansion-panel-content class="px-5">
              <div class="overview-cont">
                <v-row>
                  <v-col cols="12" md="6">
                    <h3 class="text-h5 font-weight-medium my-5 headers--text text-capitalize">{{$t('basic.include')}}</h3>
                    <p class="body-1 overview font-weight-bold" v-html="packageDetails.includes"></p>
                  </v-col>
                  <v-col cols="6">
                    <h3 class="text-h5 font-weight-medium my-5 headers--text text-capitalize">{{$t('basic.exclude')}}</h3>
                    <p class="body-1 overview font-weight-bold" v-html="packageDetails.excludes"></p>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card class="mt-10 pa-5">
          <v-card-title class="lightBlue--text text-h4 font-weight-bold pl-0">{{$t('flightSearch.addFlight')}}</v-card-title>
          <package-controls @error="displayError" @clearFlight="$store.dispatch('removeFlight')" />
        </v-card>

        <v-btn
        rounded
        color="headers white--text"
        x-large
        class="mt-15 px-15 text-h6 d-block mx-auto"
        elevation="0"
        :disabled="!originAirport || !homeAirport || !startDate || !returnDate"
        @click="redirectToFlights"
        >
        {{$t('flightSearch.flightSearch')}}
        </v-btn>
        <span v-if="!originAirport || !homeAirport" class="d-block mt-2 mb-5 caption text-center error--text">{{$t('flightSearch.flightSearchNote')}}</span>
       </template>
      <p v-else class="text-center white--text mt-5 headline text-capitalize">Oops, Couldn't get package details !</p>
    </div>
    <v-row v-else justify="center" class="mt-10">
      <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
      class="mx-auto"
      ></v-progress-circular>
    </v-row>
  </v-container>
</template>
<script>
// web services
import packages from '@/requests/packages.js'

// components imports
import packageControls from '@/components/packageControls'

// state
import { mapState } from 'vuex'

export default {
  props: ['id'],
  data () {
    return {
      alertExist: false,
      alertText: '',
      alertType: '',
      loading: true,
      overlay: false,
      tempStartDate: null
    }
  },
  components: {
    packageControls
  },
  computed: {
    ...mapState(['originAirport', 'returnAirport', 'destinationAirport', 'homeAirport',
      'classType', 'startDate', 'returnDate', 'adults', 'children',
      'oldChildren', 'infants', 'packageDetails', 'flight', 'currency'
    ])
  },
  methods: {
    displayError (msg) {
      this.alertExist = true
      this.alertType = 'error'
      this.alertText = msg
    },
    createFlightQuery () {
      const params = new URLSearchParams()
      let isMultiCityFlight = true
      if (this.originAirport.Code === this.homeAirport.Code && this.destinationAirport.Code === this.returnAirport.Code) isMultiCityFlight = false
      if (!isMultiCityFlight) {
        params.append('tripType', 'round')
        params.append('origin', this.originAirport.Code)
        params.append('destination', this.destinationAirport.Code)
        params.append('originType', this.originAirport.Type)
        params.append('destinationType', this.destinationAirport.Type)
        params.append('departureDate', this.startDate)
        params.append('arrivalDate', this.returnDate)
      } else {
        // depart flight
        params.append('tripType', 'multi')
        params.append('origin[]', this.originAirport.Code)
        params.append('originType[]', this.originAirport.Type)
        params.append('destination[]', this.destinationAirport.Code)
        params.append('destinationType[]', this.destinationAirport.Type)
        params.append('departureDate[]', this.startDate)
        // return flight
        params.append('origin[]', this.returnAirport.Code)
        params.append('originType[]', this.returnAirport.Type)
        params.append('destination[]', this.homeAirport.Code)
        params.append('destinationType[]', this.homeAirport.Type)
        params.append('departureDate[]', this.returnDate)
      }
      params.append('classType', this.classType.value)
      params.append('adults', this.adults)
      params.append('children', this.children + this.oldChildren)
      params.append('infants', this.infants)
      return params
    },
    redirectToFlights () {
      this.$router.push({ name: 'flightResults', query: { flightQuery: this.createFlightQuery().toString() } })
    }
  },
  created () {
    if (!this.packageDetails) {
      packages.getPackage(1).then(res => {
        if (res.status === 200) {
          this.$store.dispatch('setPackage', res.data[0])
        }
      })
        .catch(err => {
          this.alertExist = true
          this.alertText = err
          this.alertType = 'error'
        })
        .finally(() => {
          this.loading = false
        })
    } else this.loading = false
  }
}
</script>

<style>
 .btn-sec {
   position: sticky;
   background-color: white;
   bottom: 10px;
 }
 .overview ul {
   list-style-image: url('../../assets/check.jpeg');
 }
 .overview li {
   margin: 30px 0;
   font-size: 20px;
   color: #868686;
   text-transform: capitalize;
 }
 .overview-cont {
   background-image: url('../../assets/kaaba.svg');
   background-position: bottom right;
 }
 .v-application--is-ltr .v-expansion-panel-header {
   height: 100px;
 }
 @media screen and (max-width: 955px) {
   .btn-sec {
     position: static;
   }
 }
</style>
